import { type MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'

export const meta: MetaFunction = () => [{ title: 'refundr' }]

export default function Index() {
	return (
		<div>
			<div className="h-full">
				<div className="flex flex-col justify-center gap-8 mt-6">
					<div className="text-center">
						<h1 className="text-h1">Welcome</h1>
						{/*<p className="mt-3 text-body-md text-muted-foreground">Glad you found us</p>*/}
					</div>
					<div className="container mt-4">
						<div
							className="mx-auto max-w-5xl rounded-xl bg-white p-8 font-mono font-bold text-gray-700 shadow-lg">
							We're thrilled to team up with tax experts to support you through the upcoming tax season! Our platform simplifies the filing process,
							ensuring secure document submission for easy access to your records and facilitating improved communication with our tax consultants.
							Although you may not always get a refund, getting your taxes filed should not be a painful process along the way.
							<br/>
							<br/>
							Tax season just got a little easier.
						</div>
					</div>
					<div className="container">
						<div
							className="mx-auto max-w-5xl rounded-xl bg-white p-8 font-mono font-bold text-gray-700 shadow-lg mb-40">
							After signing in, we welcome your feedback. Whether it's about what you enjoyed, suggestions for improvement,
							or features you'd like us to develop, we're dedicated to improving year after year.
						</div>
					</div>
				</div>
			</div>
			<div className="mx-auto flex max-w-5xl justify-center border-t border-gray-500 p-5 text-center text-sm">
				<Link to="/privacy-policy">privacy</Link>&nbsp;&nbsp;|&nbsp;&nbsp;<Link to="/terms">terms</Link>
				&nbsp;&nbsp;|&nbsp;&nbsp;&#169;&nbsp;copyright
			</div>
		</div>
	)
}
